<template>
  <div
    id="chart"
    style="
      height: 265px;
      width: 100%;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    "
  >
    <apexchart
      v-if="!isLoading"
      type="pie"
      width="430"
      :options="donutSalesItemOptions"
      :series="donutSalesItemSeries"
    ></apexchart>
  </div>
</template>
<script>
export default {
  name: "DonutSalesItem",
  props: {
    _series: {
      type: Array,
      default() {
        return [];
      },
    },
    _categories: {
      type: Array,
      default() {
        return [];
      },
    },
    _title: { type: String, default: "" },
  },
  components: {},
  data() {
    return {
      isLoading: true,
      donutSalesItemSeries: [],
      donutSalesItemOptions: {
        chart: {
          type: "pie",
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false, // Вимкнути панель інструментів
          },
        },
        title: {
          text: this._title,
          align: "left",
        },
        labels: [],
        tooltip: {
          y: {
            formatter: function (value) {
              return value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            },
          },
        },
        responsive: [
          {
            breakpoint: 150,
            options: {
              chart: {
                width: 100,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
    };
  },
  methods: {
    setOptions() {
      this.donutSalesItemOptions = {
        ...this.donutSalesItemOptions,
        ...{
          labels: this._categories,
        },
      };
      this.donutSalesItemSeries = this._series;
      this.isLoading = false;
    },
  },
  created() {},
  watch: {
    _categories() {
      this.setOptions();
    },
    _title() {
      this.donutSalesItemOptions = {
        ...this.donutSalesItemOptions,
        ...{
          title: {
            text: this._title,
          },
        },
      };
    },
  },
  mounted() {
    this.setOptions();
  },
};
</script>
<style lang="scss"></style>
