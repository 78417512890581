<template>
  <div id="chart" style="height: 415px; width: 100%">
    <h2>{{ title }}</h2>
    <base-table
      :row="row"
      :columnDefs="column"
      :rowHeight="rowHeight"
      :defaultColDef="defaultColDef"
      :statusBar="statusBar"
      :settings="settings"
      :pagination="pagination"
    >
    </base-table>
  </div>
</template>
<script>
import BaseTable from "../BaseTable";
export default {
  name: "Report",
  props: {
    title: {
      type: String,
      default: "",
    },
    rowHeight: {
      type: Number,
      default: 35,
    },
    column: {
      type: Array,
      default: null,
    },
    row: {
      type: Array,
      default: null,
    },
    statusBar: {
      type: Object,
      default: null,
    },
  },
  components: {
    BaseTable,
  },
  data() {
    return {
      pagination: true,
      defaultColDef: {
        enableRowGroup: false,
        enablePivot: false,
        enableValue: false,
        width: 100,
        flex: 1,
        minWidth: 20,
        filter: false,
        sortable: true,
        resizable: true,
      },
      settings: {
        height: 390,
        //showOptionsPanel: false,
      },
    };
  },
  computed: {},
  methods: {},
  created() {},
  mounted() {},
  watch() {},
};
</script>
<style lang="scss">
</style>
