<template>
  <div
    class="v-row d-flex"
    style="flex-wrap: wrap; gap: 0px; align-items: center"
  >
    <indicator
      v-for="(indicator, index) in indicators"
      :key="index"
      :type="indicator.type"
      :title="indicator.title"
      :units="indicator.units"
      :indicator="indicator.value"
      :currency="indicator.currency"
      :additional="indicator.additional"
      @event-indicator-click="handleIndicatorClick"
    >
    </indicator>
  </div>
</template>
<script>
import Indicator from "./Indicator";

export default {
  name: "Indicators",
  components: { Indicator },
  props: {
    indicators: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  inject: [],

  data() {
    return {};
  },
  computed: {},
  methods: {
    handleIndicatorClick(type) {
      this.$emit("event-indicator-click", type);
    },
  },
  created() {},
  mounted() {},
};
</script>

<style>
.c-dashboardInfo .wrap {
  background: #ffffff;
  box-shadow: 2px 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  text-align: center;
  position: relative;
  overflow: hidden;
  padding: 40px 25px 20px;
  height: 100%;
}

.c-dashboardInfo span {
  display: block;
}

.c-dashboardInfo .wrap:after {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 10px;
  content: "";
}

.c-dashboardInfo:nth-child(1) .wrap:after {
  background: linear-gradient(82.59deg, #00c48c 0%, #00a173 100%);
}

.c-dashboardInfo:nth-child(2) .wrap:after {
  background: linear-gradient(81.67deg, #0084f4 0%, #1a4da2 100%);
}

.c-dashboardInfo:nth-child(3) .wrap:after {
  background: linear-gradient(69.83deg, #0084f4 0%, #00c48c 100%);
}

.c-dashboardInfo:nth-child(4) .wrap:after {
  background: linear-gradient(81.67deg, #ff647c 0%, #1f5dc5 100%);
}

.c-dashboardInfo__title svg {
  color: #d7d7d7;
  margin-left: 5px;
}
</style>
