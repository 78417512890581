<template>
  <div id="chart" style="height: 265px; cursor: pointer">
    <apexchart
      v-if="!isLoading"
      type="donut"
      height="280"
      :options="donutSalesOptions"
      :series="donutSalesSeries"
    ></apexchart>
  </div>
</template>
<script>
export default {
  name: "DonutSales",
  props: {
    _series: {
      type: Array,
      default() {
        return [];
      },
    },
    _categories: {
      type: Array,
      default() {
        return [];
      },
    },
    _title: { type: String, default: "" },
  },
  components: {},
  data() {
    return {
      isLoading: true,
      donutSalesSeries: [],
      donutSalesOptions: {
        chart: {
          type: "donut",
          width: 480,
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false, // Вимкнути панель інструментів
          },
        },
        title: {
          text: this._title,
          align: "left",
        },
        labels: [],

        tooltip: {
          y: {
            formatter: function (value) {
              return value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            },
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
    };
  },
  methods: {
    setOptions() {
      this.donutSalesOptions = {
        ...this.donutSalesOptions,
        ...{
          labels: this._categories,
        },
      };
      this.donutSalesSeries = this._series;
      this.isLoading = false;
    },
  },
  created() {},
  mounted() {
    this.setOptions();
  },
  watch: {
    _series() {
      this.setOptions();
    },
    _title() {
      this.donutSalesOptions = {
        ...this.donutSalesOptions,
        ...{
          title: {
            text: this._title,
          },
        },
      };
    },
  },
};
</script>
<style lang="scss"></style>
